import { Dropdown, Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import React, { useCallback, useContext, useState } from 'react';
import { Alert, Button, Modal, Select, Tag, message } from 'antd';
import { getOperatorsList } from 'services/message.service';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import CreateOperatorModal from './CreateOperatorModal/CreateOperatorModal';
import useModal from 'hooks/useModal';
import { DownOutlined } from '@ant-design/icons';
import UpdateOperatorModal from './UpdateRoleModal/UpdateOperatorModal';
import { useAuth0 } from '@auth0/auth0-react';
import UpdateStatusModal from './UpdateStatusModal/UpdateStatusModal';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { AuthUserContext } from 'components/context/AuthUserContext';

const OperatorsPage = () => {
    const createOperatorModal = useModal();
    const [data, setData] = useState([]);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
    const updateUserRoleModal = useModal();
    const updateStatusModal = useModal();
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL)
    }

    const [modifiedColumns, setModifiedColumns] = React.useState<any>(null);
    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        search_by: '',
        search_key: '',
        sort_key: 'first_name',
        sort_by: 'asc',
    });

    const renderEmail = useCallback((user: any) => {
        if (user.email) {
            return <a href={`mailto:${user.email}`}>{user.email}</a>;
        } else {
            return <p className="m-0 text-gray-500">No Email</p>;
        }
    }, []);

    const { request, loading } = useApi({
        api: getOperatorsList,
    });

    const userRoleColor = (user: any) => {
        switch (user.user_role) {
          case 'SUPER_ADMIN':
            return 'geekblue';
          case 'FINANCE':
            return 'volcano';
          case 'OPERATOR':
            return 'gold';
          case 'PRODUCT_OWNER':
            return 'orange';
          case 'TRANSPORT_OPS':
            return 'lime'; 
          case 'IT_OPS':
            return 'green';
          case 'QUALITY_ASSURANCE':
            return 'cyan'; 
          case 'SERVICE_EXCELLENT':
            return 'blue'; 
          case 'MARKETING':
            return 'purple';
          case 'ONBOARDING':
            return 'geekblue';
          case 'DREX':
            return 'volcano';
          case 'OPS_STRATEGY_1':
            return 'gold';
          case 'OPS_STRATEGY_2':
            return 'orange';
          case 'EXPANSION':
            return 'purple';
          case 'SERVICE_EXCELLENT_AGENT':
            return 'lime';
          case 'SERVICE_EXCELLENT_SUPPORT':
            return 'green';
          case 'VISMIN_ONBOARDING':
            return 'gold';
          case 'ONBOARDING_MANAGER':
            return 'orange';
          default:
            return ''; // Or any default color
        }
      };

    const columns = [
        {
            title: 'First Name',
            key: 'first_name',
            dataIndex: 'first_name',
        },
        {
            title: 'Last Name',
            key: 'last_name',
            dataIndex: 'last_name',
        },
        {
            title: 'Email',
            render: (user: any) => renderEmail(user),
        },
        {
            title: 'Role',
            render: (user: any) => (
                <div className="flex items-center">
                    <Tag color={userRoleColor(user)}>{user.user_role}</Tag>
                </div>
            ),
        },
        {
            title: 'Internal ID',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{user.id}</Text>
                </div>
            ),
        },
        {
            title: 'Status',
            render: (user: any) => (
                user.status === 'active' || user.status === 'ACTIVE' ? 
                <Tag color="green">Active</Tag> : 
                <Tag>
                    <Text color='text-gray'>Deactivated</Text></Tag>
            ),
        },
    ];

    const fetchList = useCallback(
        async (requestState: {}) => {
            try {
                const result = await request(requestState);
                let d = result.data;
                if (d.status == 'success') {
                    let operators = d.data.operators.map((c: any) => {
                        return {
                            ...c,
                            key: c.id,
                        };
                    });
                    setData(operators);
                    let pagination = d.data.pagination;
                    let p = {
                        current: pagination.page,
                        defaultCurrent: 20,
                        total: pagination.total_count,
                        pageSize: pagination.page_size,
                    };
                    setPagination(p);
                } else {
                    // TODO: error screen
                }
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const handleUpdateStatus = useCallback((user: any) => {
        updateStatusModal.show({
            content: {
                name: `${user.first_name} ${user.last_name}`,
                currentRole: user.user_role,
                email: user.email,
                status: user.status,
                id: user.id,
                newStatus: user.status === 'ACTIVE' || user.status === 'active' ? 'DEACTIVATED' : 'ACTIVE'
            },
            refreshList: () => fetchList(requestState),
        });
    },[fetchList, requestState, updateStatusModal]);



    const ColumnActions = useCallback(
        (user: any) => {
            return (
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'Edit Role',
                                key: '1',
                                onClick: () => {
                                    updateUserRoleModal.show({
                                        content: {
                                            name: `${user.first_name} ${user.last_name}`,
                                            currentRole: user.user_role,
                                            email: user.email,
                                        },
                                        refreshList: () => fetchList(requestState),
                                    });
                                },
                            },
                            {
                                label: user.status === 'active' || user.status === 'ACTIVE' ? <Text className='text-red-600'>Deactivate</Text> : 'Activate',
                                key: '2',
                                onClick: () => handleUpdateStatus(user),
                            },
                        ],
                    }}
                >
                    <Button type="ghost">
                        Actions
                        <DownOutlined />
                    </Button>
                </Dropdown>
            );
        },
        [fetchList, requestState, updateUserRoleModal, handleUpdateStatus],
    );

    useMount(() => {
        const modifyColumnsForSuperAdmin = async () => {
            if (hasActionAccess(PagesEnum.OPERATORS)) {
                setModifiedColumns([...columns, { title: '', render: (user: any) => ColumnActions(user) }]);
            } else {
                setModifiedColumns(columns)
            }
        };

        const initialize = async () => {
            await modifyColumnsForSuperAdmin();
            fetchList(requestState);
        };

        initialize();
    });

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: field,
                sort_by: order == 'ascend' ? 'asc' : 'desc',
            });
            await fetchList(requestState || {});
        },
        [fetchList, modifyFilters],
    );

    const handleOnShowModal = useCallback(() => {
        createOperatorModal.show();
    }, [createOperatorModal]);

    return (
        <ListLayout
            title="Operators"
            actionComponent={
                hasActionAccess(PagesEnum.OPERATORS) && <Button type="primary" onClick={handleOnShowModal}>
                    Invite Operator
                </Button>
            }
        >
            <Alert
                message={
                    <div>
                        To remove or update an operator,{' '}
                        <a href="https://app.clickup.com/14274986/v/fm/dkmda-12587" target="_blank">
                            request a ticket
                        </a>{' '}
                        through Click-Up
                    </div>
                }
                type="warning"
                showIcon
            />
            <Table
                loading={loading}
                columns={modifiedColumns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
                onChange={onTableChange}
            />
            <CreateOperatorModal
                modal={createOperatorModal}
                onSuccess={() => {
                    fetchList(requestState);
                }}
            />
            <UpdateOperatorModal {...updateUserRoleModal} />
            <UpdateStatusModal {...updateStatusModal} />
        </ListLayout>
    );
};

export default OperatorsPage;
