import { Card, Dropdown, Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import React, { useCallback, useRef, useState } from 'react';
import { Alert, Button, Tag, message, notification } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { createBatchInstallment, createDriver } from 'services/message.service';
import useApi from 'hooks/useApi';
import papa from 'papaparse';

const PlaygroundPage = () => {
    const hiddenFileInput = useRef(null);
    const token = JSON.parse(localStorage.getItem('userToken') || '{}');

    const {
        request: createDriverRequest,
        loading,
        result,
    } = useApi({
        api: createDriver,
    });

    const uploadCsvData = useCallback(
        async (csvResult: []) => {
            try {
                const res = await createDriverRequest({
                    token: token,
                    res: csvResult,
                });
                const { data, error } = res;
                // if (!error) {
                //     let errorRecords = data.error;
                //     if (errorRecords.length === 0) {
                //         notification.success({
                //             message: 'Success',
                //             description: 'All rows are successfully uploaded',
                //         });

                //         return;
                //     } else if (data.success?.length > 0) {
                //         notification.warning({
                //             message: 'Error',
                //             description: 'Some rows encountered error',
                //             duration: 3,
                //         });
                //     } else {
                //         notification.error({
                //             message: 'Error',
                //             description: 'Error for all rows',
                //             duration: 3,
                //         });
                //     }

                //     setSuccessNumber(data.success?.length);
                //     // await handleFailedRecords(errorRecords, "internal error")
                // } else {
                //     setSuccessNumber(0);
                //     notification.error({
                //         message: 'Error',
                //         description: error.message,
                //         duration: 3,
                //     });
                //     // await handleFailedRecords(csvResult, error.message)
                // }
            } catch (error) {
                throw error;
            }
        },
        [token, createDriverRequest],
    );

    const handleFileUpload = (event: any) => {
        (hiddenFileInput?.current as any)?.click();
    };

    const handleFile = (event: any) => {
        papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (result: any) {
                const max_rows = 3000;
                if (result.data.length > max_rows) {
                    // Maximum of 3000 rows per upload
                    notification.error({
                        message: 'Error',
                        description: `Maximum of ${max_rows} Rows per upload`,
                        duration: 3,
                    });

                    return;
                }
                result.data = result.data.map((d: any) => {
                    if (d.email == 'NULL') {
                        d.email = '';
                    }
                    if (d.driving_license_expiry == 'NULL') {
                        d.driving_license_expiry = '';
                    }
                    if (d.driving_license_number == 'NULL') {
                        d.driving_license_number = '';
                    }
                    if (d.gender == 'not_set') {
                        d.gender = 'O';
                    }
                    return d;
                });
                var i = 0;
                result.data.forEach((d: any) => {
                    d['token'] = token;
                    d['service_types'] = ['passenger_bike', 'padala_bike'];
                    d['piston_displacement'] = Number(d.piston_displacement);
                    d['year'] = Number(d.year);
                    setTimeout(function () {
                        uploadCsvData(d);
                    }, i * 1000);
                    i++;
                });
            },
        });
    };

    return (
        <ListLayout title="Welcome to the Playground!">
            <Alert
                message={
                    <div>
                        Warning! The features in this page are experimental. Please ask for advice from the developers
                        (Russell) before using them.
                    </div>
                }
                type="warning"
                showIcon
            />
            <div className="flex flex-col gap-4">
                <div className="grid grid-cols-3 gap-4">
                    <Card className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition">
                        <div className="flex justify-between items-center">
                            <Text type="title">Import Bikers</Text>
                            <Text type="label">Import bikers from a CSV file.</Text>
                        </div>
                        <input
                            hidden
                            id="upload-file"
                            type="file"
                            accept=".csv"
                            name="file"
                            ref={hiddenFileInput}
                            onChange={handleFile}
                        ></input>

                        <Button onClick={handleFileUpload} icon={<UploadOutlined />}>
                            Upload CSV
                        </Button>
                    </Card>
                </div>
            </div>
        </ListLayout>
    );
};

export default PlaygroundPage;
